import React from "react";
import { motion } from "framer-motion";
import styles from "./contact.module.scss";





const ContactPage = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      key="dev"
    >

    <div className={styles.header}>
      <h1 className={styles.pageTitle}>Contact</h1>
      <div className={styles.formInstructions}>
        Fill out the form below to get in touch
      </div>
    </div>

    
    
    <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact" className={styles.contactForm}>
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
      
      <div className={styles.formGroup}>
        <label className={styles.textInputLabel} htmlFor="name">Name:</label>
        <input className={styles.textInputField} type="text" name="name" id="name" />
      </div>

      <div className={styles.formGroup}>
        <label className={styles.textInputLabel} htmlFor="email">Email:</label>
        <input className={styles.textInputField} type="email" name="email" id="email" />
      </div>

      <div className={styles.formGroup}>
        <label className={styles.textInputLabel} htmlFor="message">Message:</label>
        <textarea className={styles.textInputField} name="message" id="message" rows="5" />
      </div>

      <button className={styles.formSubmitBtn} type="submit">Send</button>
    </form>

    </motion.div>
  )
}

export default ContactPage